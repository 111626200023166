<template>
  <b-card-footer>
    <b-input-group v-if="!dataItem.closed">
      <b-form-input
        v-model="messageItem.message"
        placeholder="Bir mesaj yazın..."
        @keydown.enter="sendMessage"
      />
      <b-input-group-append>
        <b-button
          variant="success"
          :disabled="!messageItem.message"
          @click="sendMessage"
        >
          <FeatherIcon icon="SendIcon" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <b-alert
      v-else
      show
      variant="warning"
    >
      <div class="alert-body text-center">
        <span v-if="dataItem.user_closed">
          {{ $t('MSG_CLOSED_START') }} {{ dataItem.user_closed }}{{ $t('MSG_CLOSED_END') }}
        </span>
        <span v-if="dataItem.customer_closed">
          {{ $t('MSG_CLOSED_START') }} {{ dataItem.customer_closed }}{{ $t('MSG_CLOSED_END') }}
        </span>
      </div>
    </b-alert>
  </b-card-footer>
</template>

<script>
import {
  BCardFooter,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BAlert,
} from 'bootstrap-vue'

export default {
  name: 'CardFooter',
  components: {
    BCardFooter,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BAlert,
  },
  props: {
    sendMessage: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['chatMessages/dataItem']
    },
    messageItem() {
      return this.$store.getters['chatMessages/messageItem']
    },
  },
}
</script>
