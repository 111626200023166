<template>
  <div>
    <div
      class="mb-1 d-flex"
      :class="itemData.id_customer_accounts? 'justify-content-end' : 'justify-content-start'"
    >
      <div
        class="w-50 text-white pt-1 pl-1 pb-1 rounded-lg d-flex align-items-center justify-content-between"
        :class="itemData.id_customer_accounts? 'bg-light-secondary' : 'bg-light-warning'"
      >
        <div>
          <div>
            {{ itemData.message }}
          </div>
          <div class="font-small-1 mt-1">
            {{ moment(itemData.created).format('llll') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MessageItem',
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
}
</script>
